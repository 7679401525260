import { typeGenerator } from './product_description';

/**
 * Manipulate object of cart to have just neccesary property
 *
 * @param data data of single cart
 * @return new object of cart
 */
export const manipulateCartArray = ( data ) => {
  return {
    image: data.picture ? data.picture : '/landing/card/dummy.svg',
    title: data.categoryName,
    _id: data._id,
    course_id: data.courseId || data.course_id,
    courseOrganizer: data.courseOrganizer.code || data.courseOrganizer || '',
    price: parseFloat( data.price[ '$numberDecimal' ] ) || parseFloat( data.price ),
    description: data.description,
    name: data.name || data.courseTitle,
    discount: parseFloat( data.discount[ '$numberDecimal' ] ) || parseFloat( data.discount ) || 0,
    picture: data.picture,
    shortDescription: data.shortDescription || data.shortDesc,
    createdAt: data.createdAt,
    categoryName: data.categoryName,
    courseSlug: data.courseSlug,
    categorySlug: data.categorySlug,
    isBundling: data?.isBundling || false,
    listCourse: data?.listCourse || [],
    count: data.count,
  };
};

/**
 * Remove duplicate item from array of object base on identifier
 *
 * @param data data of the array
 * @param identifier identifier of duplicated item
 * @return array without duplicate item
 */
export const removeDuplicateElementOfArray = ( data, identifier ) => {
  return data.filter(
    ( value, index, self ) =>
      index ===
      self?.findIndex((t) => {
        if (identifier) {
          return t[identifier] === value[identifier];
        }

        return t === value;
      } ),
  );
};

/**
 * There are so many differences in item data stucture, use this function for make all the data is having same structur
 * @param {any} item
 * @param {boolean} isProduct
 * @returns real data structure of an item
 */
export const synchronizedSingleCartData = ( { item, isProduct } ) => {

  if ( item.lmsBundleId || item.isBundle ) {
    //This means the item is a bundle
    return {
      _id: item._id,
      itemId: item.itemId || item.lmsBundleId,
      slug: item.slug,
      name: item.name || item.title,
      image: typeof item.image === 'string' ? item.image : item.image.path,
      provider: typeof item.provider === 'string' ? item.provider : item?.provider?.name,
      description: item.description,
      discount: parseFloat( item.discount?.[ '$numberDecimal' ] ) || parseFloat( item.discount ) || 0,
      price: parseFloat( item.price?.[ '$numberDecimal' ] ) || parseFloat( item.price ),
      listCourse: item.listCourse || item.items,
      isProduct: false,
      isBundle: true,
      bundleType: item.bundleType,
    };
  }

  return {
    _id: item._id,
    itemId: item.productId ?? item.courseId ?? item.itemId,
    slug: item.slug ?? item.courseSlug,
    name: ( isProduct ? item.title : item.courseTitle ) ?? item.name,
    image: ( isProduct ? item.image : item.picture ) ?? item.image ?? null,
    provider: item?.provider?.code ?? item?.courseOrganizer?.code ?? item?.courseOrganizer ?? item?.provider ?? '',
    description: item.description,
    categoryName: item.categoryName ?? ( ! isProduct && 'Bundle Courses' ),
    discount: parseFloat( item.discount?.[ '$numberDecimal' ] ) || parseFloat( item.discount ) || 0,
    price: parseFloat( item.price?.[ '$numberDecimal' ] ) || parseFloat( item.price ),
    isBundling: item.isBundling ?? false,
    listCourse: item.listCourse ?? [],
    isProduct: isProduct,
    type: isProduct ? typeGenerator( item.type ) : '',
    language: item.language
  };
};

/**
 * Function for synchronizing all items inside array
 * @param {any[]} cartArray array of cart whose item will be synchronized using synchronizedSingleCartData function
 * @returns new array whose item already synchronized
 */
export const synchronizedCartData = ( cartArray ) => {
  return cartArray.map( ( data ) => {
    return synchronizedSingleCartData( { item: data, isProduct: data.isProduct || !! data.productId } );
  } );
};

/**
 * If an element that exist in firstArray is exist in secondArray, remove it from firstArray
 * @param {any[]} firstArray array that will be checked
 * @param {any[]} secondArray array whose item will be removed from firstArray
 * @param {string} identifier identifier of item in array to identify if the item is exist in both array
 * @returns
 */
export const onlyUniqueElement = ( firstArray, secondArray, identifier ) => {
  return firstArray.filter(
    (firstArrayitem) =>
      secondArray?.findIndex((secondArrayItem) => secondArrayItem[identifier] === firstArrayitem[identifier]) < 0
  );
};

import { useRouter } from 'next/router';
import { useCallback } from 'react';

// Component
import FooterMenuItem from './FooterMenuItem';
import FooterMenuTitle from './FooterMenuTitle';
import SocialMediaItem from './SocialMediaItem';

const pageWithBottonSheet = ['/cart', '/checkout', '/courses/[courseId]', '/products/[productId]'];
const bottomSheetClassName = {
  ['/cart']: 'cart',
  ['/checkout']: 'checkout',
  ['/courses/[courseId]']: 'course-id',
  ['/products/[productId]']: 'product-id',
};

const helpMenuItems = [
  {
    menuName: 'Privay Policy',
    link: 'https://lerero.com/privacy-policy/',
  },
  {
    menuName: 'Terms and Conditions',
    link: 'https://lerero.com/terms-and-conditions/',
  },
  {
    menuName: 'FAQs',
    link: 'https://lerero.com/faqs/',
  },
];

const platformMenuItems = [
  {
    menuName: 'Features',
    link: 'https://lerero.com/features/',
  },
  {
    menuName: 'Request Demo',
    link: 'https://lerero.com/request-demo/',
  },
];

const companyMenuItems = [
  {
    menuName: 'About Us',
    link: 'https://lerero.com/about-us/',
  },
  {
    menuName: 'Contact Us',
    link: 'https://lerero.com/contact-us/',
  },
];

const socialMenuItems = [
  {
    link: 'https://twitter.com/lerero_hq',
    icon: '/footer/fa-twitter.svg',
    alt: 'Twitter',
  },
  {
    link: 'https://www.facebook.com/profile.php?id=100090685698996&is_tour_completed=true',
    icon: '/footer/fa-facebook-square.svg',
    alt: 'Facebook',
  },
  {
    link: 'https://www.linkedin.com/company/lerero/',
    icon: '/footer/fa-linkedin.svg',
    alt: 'LinkedIn',
  },
  {
    link: 'https://www.instagram.com/lerero_official/',
    icon: '/footer/fa-instagram.svg',
    alt: 'Instagram',
  },
  {
    link: 'https://www.youtube.com/channel/UCmD-Tz6xJYwvHa4x3wcrcjQ',
    icon: '/footer/fa-youtube.svg',
    alt: 'Youtube',
  },
  {
    link: 'https://medium.com/@Lerero',
    icon: '/footer/fa-medium.svg',
    alt: 'Tiktok',
  },
];

const Footer = () => {
  const router = useRouter();

  const isHaveBottomSheet = useCallback((pathname) => {
    return pageWithBottonSheet.includes(pathname);
  }, []);

  return (
    <footer
      className={`footer-wrapper ${
        isHaveBottomSheet(router.pathname) ? bottomSheetClassName[router.pathname] : 'no-bottomsheet'
      } fixed-bottom`}
    >
      <div className="display-flex flex-column flex-lg-row align-items-start mb-3">
        {/* Help Section */}
        <div>
          <FooterMenuTitle title="Help"></FooterMenuTitle>
          {helpMenuItems.map((menuItem) => {
            return (
              <FooterMenuItem
                link={menuItem.link}
                menuName={menuItem.menuName}
                key={menuItem.menuName}
              ></FooterMenuItem>
            );
          })}
        </div>

        {/* Platform Section */}
        <div className="ms-0 ms-lg-5" style={{ marginLeft: '40px' }}>
          <FooterMenuTitle title="Platform"></FooterMenuTitle>
          {platformMenuItems.map((menuItem) => {
            return (
              <FooterMenuItem
                link={menuItem.link}
                menuName={menuItem.menuName}
                key={menuItem.menuName}
              ></FooterMenuItem>
            );
          })}
        </div>

        {/* Company Section */}
        <div className="ms-0 ms-lg-5" style={{ marginLeft: '40px' }}>
          <FooterMenuTitle title="Company"></FooterMenuTitle>
          {companyMenuItems.map((menuItem) => {
            return (
              <FooterMenuItem
                link={menuItem.link}
                menuName={menuItem.menuName}
                key={menuItem.menuName}
              ></FooterMenuItem>
            );
          })}
        </div>
      </div>

      {/* Social Media Section */}
      <div className="footer-container d-flex flex-column-reverse flex-lg-row align-items-center align-items-lg-end mt-5 mt-lg-0">
        <div className="footer-menu-container col-lg-4 col-12 justify-content-center justify-content-lg-start pt-4">
          <span className="font-titilium text-white font-size-14">© 2023 Lerero. All rights reserved.</span>
        </div>
        <center className="col-lg-4 col-12">
          {socialMenuItems.map((menuItem) => {
            return (
              <SocialMediaItem
                key={menuItem.alt}
                link={menuItem.link}
                alt={menuItem.alt}
                icon={menuItem.icon}
              ></SocialMediaItem>
            );
          })}
        </center>
      </div>
      <div className="footer-top-pattern"></div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import Router from 'next/router';

// Utils
import { cancelEventBubble } from 'utils/cancel_event_bubble';

const PrimaryMenu = (props) => {
  const { toggle, toggleValue, handleClick, title, noToggle } = props;
  return (
    <div
      className={`mobile-menu__menu-title ${noToggle ? '' : toggle === toggleValue ? 'menu-opened' : ''}`}
      onClick={handleClick}
    >
      <div>{title}</div>
      {!noToggle ? <i className="bi bi-chevron-down" /> : null}
    </div>
  );
};

const ChildMenu = (props) => {
  const { isHasChild, name, id, slug, openedChild, setOpenedChild, handleClose, childrenArray } = props;

  if (isHasChild)
    return (
      <div key={slug} className={`mobile-menu__menu-container ${openedChild === slug ? 'menu-opened' : ''}`}>
        <div
          className={`mobile-menu__parent`}
          onClick={() =>
            childrenArray?.length > 0
              ? openedChild !== slug
                ? setOpenedChild(slug)
                : setOpenedChild('')
              : () => {
                  handleClose();
                  Router.push({
                    pathname: '/category',
                    query: {
                      category: slug,
                      name: name,
                    },
                  });
                }
          }
        >
          <div>{name}</div>
          {childrenArray?.length > 0 ? <i className="bi bi-chevron-down" /> : ''}
        </div>
        {childrenArray?.length > 0 && openedChild.includes(slug) && (
          <>
            {childrenArray.map((child) => {
              return (
                <div
                  key={child.categorySlug}
                  className="mobile-menu__child"
                  onClick={() => {
                    handleClose();
                    Router.push({
                      pathname: '/category',
                      query: {
                        category: child.categorySlug,
                        parent: slug,
                      },
                    });
                  }}
                >
                  <div>{child.categoryName}</div>
                </div>
              );
            })}
            <div
              className="button-view-all"
              onClick={() => {
                handleClose();
                Router.push({
                  pathname: '/category',
                  query: {
                    category: slug,
                    name: name,
                  },
                });
              }}
            >
              View All
            </div>
          </>
        )}
      </div>
    );

  return (
    <div
      key={id}
      className={`mobile-menu__menu-container`}
      onClick={() => {
        handleClose();
        Router.push({
          pathname: '/category',
          query: { provider: id, name: name },
        });
      }}
    >
      <div className="mobile-menu__parent">
        <div>{name}</div>
      </div>
    </div>
  );
};

const MobileMenu = (props) => {
  const { isMenuOpened, toggle, menu, provider, openedChild, setOpenedChild, setIsMenuOpened, setToggle } = props;

  const handleClose = () => {
    setIsMenuOpened(false);
    setToggle('');
    setOpenedChild('');
  };

  return (
    <div className={`mobile-menu d-lg-none ${isMenuOpened ? 'opened' : ''}`} onClick={() => setIsMenuOpened(false)}>
      <div className="mobile-menu-container" onClick={(e) => cancelEventBubble(e)}>
        <div className="mobile-menu-content">
          <PrimaryMenu
            title={'Select Category'}
            handleClick={
              toggle === 'category'
                ? () => {
                    setToggle('');
                    setOpenedChild('');
                  }
                : () => {
                    setToggle('category');
                  }
            }
            toggle={toggle}
            toggleValue={'category'}
          />
          {toggle === 'category' &&
            menu.map((item) => {
              return (
                <ChildMenu
                  key={item.categorySlug}
                  slug={item.categorySlug}
                  name={item.categoryName}
                  isHasChild
                  childrenArray={item.children}
                  setOpenedChild={setOpenedChild}
                  openedChild={openedChild}
                  handleClose={handleClose}
                />
              );
            })}
        </div>
        <div className="mobile-menu-content">
          <PrimaryMenu
            title={'Providers'}
            handleClick={() => {
              setToggle(toggle === 'providers' ? '' : 'providers');
              setOpenedChild('');
            }}
            toggle={toggle}
            toggleValue={'providers'}
          />
          {toggle === 'providers' &&
            provider.map((item) => {
              return item.name.replace(/\s/g, '') !== '' ? (
                <ChildMenu key={item.id} isHasChild={false} name={item.name} id={item.id} handleClose={handleClose} />
              ) : null;
            })}
        </div>
        <div className="mobile-menu-content">
          <PrimaryMenu
            title={'Products'}
            noToggle={true}
            handleClick={() => {
              setOpenedChild('');
              handleClose();
              Router.push({
                pathname: '/category',
                query: {
                  products: 'all',
                },
              });
            }}
          />
        </div>
      </div>

      {/* Close Button */}
      <div className="mobile-menu-close">
        <font color="#323232" className="p-0 p-lg-3 close-logo d-lg-none" onClick={() => handleClose()}>
          <i className="bi bi-x d-lg-inline d-flex"></i>
        </font>
      </div>
    </div>
  );
};

export default MobileMenu;

import React from 'react';
import ICONS from 'utils/icons';

export const NoChildItem = ({ onClickFunction, name }) => {
  return (
    <div className="navbar-no-child___container">
      <p className="navbar-no-child___item" onClick={() => onClickFunction()}>
        {name}
      </p>
    </div>
  );
};

export const MainItem = ({ name, toggle, identifier, noToggle }) => {
  return (
    <div className="nav-menu__dropdown cursor-pointer">
      <span>{name}</span>
      {!noToggle ? (
        <img src={ICONS.chevronDownBlack} className={`${toggle === identifier ? 'opened' : ''}`}></img>
      ) : null}
    </div>
  );
};

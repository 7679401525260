import { useRouter } from 'next/router';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import '../styles/globals.scss';
import { useEffect } from 'react';
import GoogleAnalytics from 'components/GoogleAnalytics';

// import 'animate.css';

const navbarExceptions = {
  // "course-description": "course-description",
};
const footerExceptions = {
  // "course-description": "course-description",
};

// route that allowed to have checkout data
const checkoutDataRoute = ['/checkout', '/cart'];

const successPaymentRoute = ['/payment'];

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const CheckException = (exception) => {
    const pathname = router.pathname.split('/') || [];
    for (let x = 0; x < pathname.length; x++) {
      if (exception[pathname[x]]) return false;
    }
    return true;
  };

  useEffect(() => {
    // if route is not between the checkoutDataRoute array, delete the checkout data from local storage
    if (router.pathname) {
      if (!checkoutDataRoute.includes(router.pathname)) {
        localStorage.removeItem('cart-checked');
        localStorage.removeItem('cart-purchased');
        localStorage.removeItem('skip-cart-validation');
        localStorage.removeItem('customer-data');
        localStorage.removeItem('customer-data-phone');
      }

      if (!successPaymentRoute.includes(router.pathname)) {
        localStorage.removeItem('has-voucher');
      }
    }
  }, [router.pathname]);

  return (
    <>
      <GoogleAnalytics />
      {CheckException(navbarExceptions) && <Navbar />}
      <Component {...pageProps} />
      {CheckException(footerExceptions) && <Footer />}
    </>
  );
}

// MyApp.getInitialProps = async (appContext) => {
//   const { ctx, router } = appContext;

//   // if (router.route.includes("/admin/") && !amsi) {
//   //   // Redirect to login page if not authenticated
//   //   ctx.res.writeHead(302, { Location: "/" });
//   //   ctx.res.end();
//   // }
//   return {};
// };

export default MyApp;

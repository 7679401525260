/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

//Component
import { MainItem, NoChildItem } from './DropdownItem';
import ItemContainer from './ItemContainer';
import MobileMenu from './MobileMenu';

// utils
import { lereroFetch } from 'utils/fetch';
import { synchronizedSingleCartData } from 'utils/manipulate_array';
import { generateCoverImageUrl } from 'utils/generate_cover_image_url';

import { truncateText } from 'utils/helper';

export default function Navbar() {
  const router = useRouter();

  const searchRef = useRef(null);

  const [menu, setMenu] = useState([]);
  const [provider, setProvider] = useState([]);
  const [toggle, setToggle] = useState('');
  const [cartTotal, setCartTotal] = useState(0);
  const [cart, setCart] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [showDetailCart, setShowDetailCart] = useState(false);
  const [isSearchBarOpened, setIsSearchBarOpened] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [openedChild, setOpenedChild] = useState('');
  const [openedChildDesktop, setOpenedChildDesktop] = useState('');

  // When user is in cart page, do not show the detail of the cart. This will be applied only in mobile view.
  useEffect(() => {
    if (router.pathname === '/cart') {
      setShowDetailCart(false);
    }
  }, [router]);

  /**
   * function for getting category list to be shown in navbar
   */
  const loadMenu = async () => {
    const response = await lereroFetch('GET', `/categories/dropdown`);
    if (response.success) {
      setMenu(response.data);
    }
  };

  /**
   * function for getting provider list to be shown in navbar
   */
  const loadMenuProvider = async () => {
    const response = await lereroFetch('GET', `/items/list/providers`);
    if (response.success) {
      setProvider(response.data);
    }
  };

  // load category and provider in first render
  useEffect(() => {
    loadMenu();
    loadMenuProvider();
  }, []);

  useEffect(() => {
    let _cart = [];
    if (localStorage.getItem('cart')) {
      _cart = JSON.parse(localStorage.getItem('cart'));
    }

    const synchronizedCartData = _cart.map((item) => {
      return synchronizedSingleCartData({ item: item, isProduct: item.isProduct || false });
    });

    setCart(synchronizedCartData);
    setCartTotal(synchronizedCartData.length);
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('search');
    if (search) setSearchVal(search);
  }, [router.pathname]);

  useEffect(() => {
    if (!router.asPath.includes('/category?search=')) {
      setSearchVal('');
    }
  }, [router.asPath]);

  return (
    <div className={`nav-wrapper ${isSearchBarOpened ? 'search-bar-opened' : ''}`}>
      <div className="menu-logo d-flex d-lg-none">
        <img className="menu" src="/navbar/menu.svg" alt="menu" onClick={() => setIsMenuOpened(true)} />
      </div>
      <div className="nav-menu nav-menu-left justify-content-lg-start justify-content-center w-lg-25">
        {/* Logo Section */}
        <div className="nav-logo">
          <Link href={'https://lerero.com'} passHref={true}>
            <a target="_blank">
              <img
                className="logo d-lg-flex"
                src="/navbar/lerero_logo_new_2.svg"
                alt="logo"
                style={{ width: '205px', height: '69px' }}
              />
            </a>
          </Link>
        </div>

        {/* Category Section */}
        <div className="d-lg-flex d-none">
          <ItemContainer setToggle={setToggle} identifier={'category'}>
            {/* Main item in navbar */}
            <MainItem toggle={toggle} name={'Select Category'} identifier={'category'}></MainItem>

            {/* dropdown item */}
            {toggle === 'category' ? (
              <div className="position-absolute pt-3">
                <div className="nav-child">
                  <NoChildItem
                    onClickFunction={() => {
                      setToggle('');
                      Router.push({
                        pathname: '/category',
                        query: {
                          category: 'all',
                        },
                      });
                    }}
                    name={'All Categories'}
                  ></NoChildItem>
                  {menu.map((menu_data, index) => {
                    return menu_data.children && menu_data.children.length > 0 ? (
                      <div
                        className="navbar-with-child___container"
                        onMouseEnter={() =>
                          setOpenedChildDesktop(`${menu_data.categorySlug}-${menu_data.categoryName}`)
                        }
                        onMouseLeave={() => setOpenedChildDesktop(``)}
                        onTouchEnd={() => setOpenedChildDesktop(``)}
                      >
                        <div className="navbar-with-child___item">
                          <p
                            key={`child-${index + 1}`}
                            onClick={() => setOpenedChildDesktop(`${menu_data.categorySlug}-${menu_data.categoryName}`)}
                          >
                            {menu_data.categoryName}
                          </p>
                          <span className="chevron-image"></span>
                        </div>
                        <div
                          className={`navbar-category-child ${
                            openedChildDesktop === `${menu_data.categorySlug}-${menu_data.categoryName}`
                              ? 'd-block'
                              : ''
                          }`}
                        >
                          {menu_data.children.map((child, index2) => {
                            return (
                              <NoChildItem
                                key={`child-${index2 + 1}`}
                                onClickFunction={() => {
                                  setOpenedChildDesktop('');
                                  setToggle('');
                                  Router.push({
                                    pathname: '/category',
                                    query: {
                                      category: child.categorySlug,
                                      parent: menu_data.categorySlug,
                                    },
                                  });
                                }}
                                name={child.categoryName}
                              ></NoChildItem>
                            );
                          })}
                          <div className="navbar-all-item___container">
                            <p
                              className="navbar-all-item___item"
                              key={`child-${menu_data.children.length + 1}`}
                              onClick={() => {
                                setToggle('');
                                setOpenedChildDesktop('');
                                Router.push({
                                  pathname: '/category',
                                  query: {
                                    category: menu_data.categorySlug,
                                    name: menu_data.categoryName,
                                  },
                                });
                              }}
                            >
                              {`View All ${menu_data.categoryName} Courses`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoChildItem
                        onClickFunction={() => {
                          setToggle('');
                          setOpenedChildDesktop('');
                          Router.push({
                            pathname: '/category',
                            query: { category: menu_data.categorySlug },
                          });
                        }}
                        name={menu_data.categoryName}
                      ></NoChildItem>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </ItemContainer>
        </div>

        {/* Provider Section */}
        <div className="d-lg-flex d-none">
          <ItemContainer setToggle={setToggle} identifier={'providers'}>
            {/* Main item in navbar */}
            <MainItem name={'Providers'} toggle={toggle} identifier={'providers'}></MainItem>

            {/* Dropdown */}
            {toggle === 'providers' ? (
              <div className="position-absolute pt-3">
                <div className="nav-child" style={{ maxHeight: '350px', top: '120%', overflowY: 'scroll' }}>
                  {provider.map((menu_data, index) => {
                    return (
                      <NoChildItem
                        key={index}
                        onClickFunction={() => {
                          setToggle('');
                          setOpenedChildDesktop('');
                          Router.push({
                            pathname: '/category',
                            query: { provider: menu_data.id, name: menu_data.name },
                          });
                        }}
                        name={menu_data.name}
                      ></NoChildItem>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </ItemContainer>
        </div>
      </div>

      <div className="nav-menu nav-menu-right w-100">
        {/* Search Bar */}
        <div className="nav-menu__search-bar">
          <font color="#0666f0" className="p-0 p-lg-3 search-logo" onClick={() => setIsSearchBarOpened(true)}>
            <i className="bi bi-search d-lg-inline d-flex"></i>
          </font>
          <form
            style={{ display: 'contents' }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input
              type="text"
              className="search-field width-100 font-size-14 d-lg-flex d-none"
              placeholder="Search for courses/products/bundles"
              value={searchVal}
              ref={searchRef}
              onChange={(e) => setSearchVal(e.target.value)}
            />
            <button
              className="search-button display-right d-lg-inline-block d-none fw-bold"
              onClick={() => {
                Router.push(`/category?search=${searchVal}`);
                searchRef.current.blur();
              }}
            >
              Search
            </button>
          </form>
          <font
            color="#323232"
            className="p-0 p-lg-3 font-size-24 close-logo d-lg-none"
            onClick={() => {
              setIsSearchBarOpened(false);
              setSearchVal('');
            }}
          >
            <i className="bi bi-x d-lg-inline d-flex"></i>
          </font>
        </div>

        {/* Cart Icon */}
        {/* onTouchEnd is fired when click the cart. When click the cart, do not show the detail of the cart so set it to false */}
        <div
          className="nav-menu__cart cursor-pointer"
          onMouseEnter={() => setShowDetailCart(true)}
          onMouseLeave={() => setShowDetailCart(false)}
          onTouchEnd={() => setShowDetailCart(false)}
          onClick={() => Router.push('/cart')}
        >
          <font color="#0666f0" className="cart-logo">
            <i className="bi bi-cart2 d-lg-inline d-flex"></i>
          </font>
          {cartTotal !== 0 ? (
            <button className="cart-bubble" onClick={() => Router.push('/cart')}>
              {cartTotal}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>

      {/* Cart List */}
      <div className={`div-cart ${showDetailCart ? '' : 'display-none'}`}>
        <table width="100%">
          <tbody>
            {cart.length > 0 ? (
              cart.map((data, index) => {
                return (
                  <tr key={index}>
                    <td width="22%" style={{ verticalAlign: 'top' }}>
                      <center>
                        <img
                          style={{ borderRadius: '50px' }}
                          className="landing-card-image profile-small"
                          src={generateCoverImageUrl(data.image)}
                          alt="Bedroom organization"
                        />
                      </center>
                    </td>
                    <td className="p-1">
                      <p className="font-size-13 mb-2 font-weight-700">{truncateText(data.name, 27)}</p>
                      <p className="font-size-12 text-secondary">{truncateText(data.categoryName, 27)}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td width="22%" style={{ verticalAlign: 'top' }}></td>
                <td className="p-1">
                  <p className="font-size-13 mb-2 font-weight-700">{''}</p>
                  <p className="font-size-12 text-secondary">{'There is no item'}</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Menu in Mobile */}
      <MobileMenu
        menu={menu}
        provider={provider}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        openedChild={openedChild}
        setOpenedChild={setOpenedChild}
        toggle={toggle}
        setToggle={setToggle}
      />
    </div>
  );
}

/**
 * generate language name based on id language
 * @param {string} language id of language
 * @returns language name
 */
export const languageGenerator = ( language ) => {
  const languageCollection = {
    english: 'English',
    french: 'French',
    arabic: 'Arabic',
    spanish: 'Spanish',
  };

  return languageCollection[ language?.toLowerCase() ];
};

/**
 * generate formatted reading time based on object of duration
 * @param duration object of duration, h = hours, m = minutes, s = seconds
 * @returns formatted reading time
 */
export const readingTimeGenerator = (duration) => {
  let readingTime = [];


  if ( ! duration ) {
    return;
  }

  if ( duration[ 'M' ] ) {
    readingTime.push( `${duration[ 'M' ]} month${duration[ 'M' ] > 1 ? `s` : ''}` );
  }

  if (duration?.['w']) {
    readingTime.push(`${duration['w']} week${duration['w'] > 1 ? `s` : ''}`);
  }

  if (duration?.['d']) {
    readingTime.push(`${duration['d']} day${duration['d'] > 1 ? `s` : ''}`);
  }

  if (duration?.['h']) {
    readingTime.push(`${duration['h']} hour${duration['h'] > 1 ? `s` : ''}`);
  }

  if (duration?.['m']) {
    readingTime.push(`${duration['m']} minute${duration['m'] > 1 ? `s` : ''}`);
  }

  if (duration?.['s']) {
    readingTime.push(`${duration['s']} second${duration['s'] > 1 ? `s` : ''}`);
  }

  if (readingTime.length === 0) {
    return;
  }

  return readingTime.join(', ');
};

/**
 * generate produt type based on id of product type
 * @param {string} type id of product type
 * @returns product type
 */
export const typeGenerator = ( type ) => {
  const typeCollection = {
    pdf: 'E-Book',
    [ 'e-book' ]: 'E-Book',
  };

  return typeCollection[ type?.toLowerCase() ];
};

/**
 * generate formatted date based on date string
 * @param {string} rawDate date string from api
 * @returns formatted date
 */
export const dateGenerator = ( rawDate ) => {
  const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if ( ! rawDate ) {
    return '';
  }

  const convertedData = new Date( rawDate );
  const year = convertedData.getFullYear();
  const month = monthArray[ convertedData.getMonth() ];
  const date = convertedData.getDate();

  return `${date} ${month} ${year}`;
};

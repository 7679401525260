import React from 'react';

const ItemContainer = (props) => {
  const { children, setToggle, identifier, noToggle, onClick } = props;
  return (
    <div
      className="width-100"
      onClick={!noToggle ? () => {} : () => onClick()}
      onMouseOver={!noToggle ? () => setToggle(identifier) : () => {}}
      onMouseLeave={!noToggle ? () => setToggle('') : () => {}}
    >
      {children}
    </div>
  );
};

export default ItemContainer;

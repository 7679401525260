const ICONS = {
  checklist: '/global/check.svg',
  chevronUp: '/global/chevron_up.svg',
  chevronDown: '/global/chevron_down.svg',
  chevronDownBlack: '/global/chevron_down_black.svg',
  playButton: '/global/play_button.svg',
  star: '/global/star.svg',
  paymentCard: '/global/payment_credit_card.svg',
  playButtonGrey: '/global/play_button_grey.svg',
  lock: '/global/lock.svg',
};

export default ICONS;

import React from 'react';

const FooterMenuTitle = (props) => {
  const { title } = props;

  return (
    <p>
      <font className="font-titilium text-white font-size-20 font-weight-700">{title}</font>
    </p>
  );
};

export default FooterMenuTitle;

/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import React from 'react';

const SocialMediaItem = (props) => {
  const { link, icon, alt } = props;
  return (
    <Link href={link} passHref>
      <a target="_blank">
        <img src={icon} alt={alt} className="" style={{ marginRight: '20px' }} />
      </a>
    </Link>
  );
};

export default SocialMediaItem;

import Link from 'next/link';
import React from 'react';

const FooterMenuItem = (props) => {
  const { link, menuName } = props;
  return (
    <p>
      <Link href={link} passHref>
        <a target="_blank">
          <font className="font-titilium text-white font-size-16 " style={{ marginRight: '20px' }}>
            {menuName}
          </font>
        </a>
      </Link>
    </p>
  );
};

export default FooterMenuItem;

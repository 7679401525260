import store from "store";
import Router from "next/router";

export async function lereroFetch(
  fetchType,
  const_api_url,
  objData = {},
  putAuthToken = true
) {
  let header = {
    "Content-Type": "application/json",
    AllowedOrigin: "*",
  };
  if (putAuthToken) {
    let amsi = store.get("amsi");
    if (amsi && amsi.accessToken) {
      header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + amsi.accessToken,
        AllowedOrigin: "*",
      };
    }
  }
  let resp = [];
  try {
    if (
      fetchType.toUpperCase() === "GET" ||
      (fetchType.toUpperCase() === "DELETE" && objData.length === 0)
    ) {
      resp = await fetch(process.env.NEXT_PUBLIC_API_URL + const_api_url, {
        method: fetchType,
        headers: header,
      });
    } else {
      resp = await fetch(process.env.NEXT_PUBLIC_API_URL + const_api_url, {
        method: fetchType,
        headers: header,
        body: JSON.stringify(objData),
      });
    }
    let res = await resp.json();
    const errorCode = resp.status;

    // if (errorCode === 403) {
    //   Router.push({ pathname: "/403" });
    // }
    if (errorCode === 401) {
      Router.push({ pathname: "/" });
    }
    // else if (errorCode === 500) {
    //   Router.push({ pathname: "/500" });
    // }
    return res;
  } catch (e) {
    return {
      status: false,
      message: e.message,
    };
  }
}

export async function lereroFetchFile(
  fetchType,
  const_api_url,
  objData = {},
  putAuthToken = true
) {
  let header = {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW; application/octet-stream ",
  };
  if (putAuthToken) {
    let amsi = store.get("amsi");
    if (amsi && amsi.accessToken) {
      header = {
        Authorization: "Bearer " + amsi.accessToken,
      };
    }
  }
  try {
    let resp = await fetch(process.env.NEXT_PUBLIC_API_URL + const_api_url, {
      method: fetchType,
      headers: header,
      body: objData,
    });

    let res = await resp.json();
    const errorCode = resp.status;

    // if (errorCode === 403) {
    //   Router.push({ pathname: "/403" });
    // }
    if (errorCode === 401) {
      Router.push({ pathname: "/" });
    }
    // else if (errorCode === 500) {
    //   Router.push({ pathname: "/500" });
    // }
    return res;
  } catch (error) {
    return {
      status: false,
      message: error.message,
    };
  }
}

/**
 * When image from API doesn't have baseUrl, add baseUrl so the url that given to src is real url
 * @param {string} url url of image
 * @returns real url of image
 */
export const generateCoverImageUrl = (url) => {
  url = typeof url ==='string' ? url : ''

  let realUrl = '';

  if (url.slice(0, 5) === 'https') {
    realUrl = url;
  } else realUrl = `${process.env.NEXT_PUBLIC_API_LMS_URL}${url}`;

  return realUrl;
};
